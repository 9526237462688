import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/pengumuman",
    name: "Announncement",
    component: () => import("../views/home/Announcement.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/home/About.vue"),
  },
  {
    path: "/penilaian",
    name: "Penilaian",
    component: () => import("../views/home/assesment/index.vue"),
  },
  {
    path: "/event-penilaian",
    name: "Event Penilaian",
    component: () => import("../views/home/assesment/Event.vue"),
  },
  {
    path: "/kebijakan",
    name: "Kebijakan",
    component: () => import("../views/home/Kebijakan.vue"),
  },
  {
    path: "/pencetak-kurikulum-merdeka",
    name: "pencetak-Kurikulum-Merdeka",
    component: () => import("../views/home/PencetakKurikulumMerdeka.vue"),
  },
  {
    path: "/pencetak-kurikulum-k13",
    name: "pencetak-Kurikulum-k13",
    component: () => import("../views/home/PencetakKurikulumK13.vue"),
  },
  {
    path: "/pelaku",
    redirect: "/pelaku/pembinaan",
    name: "parent-pelaku",
    component: () => import("../views/home/pelaku/index.vue"),
    children: [
      {
        path: "pembinaan",
        name: "Pembinaan",
        component: () => import("../views/home/pelaku/pembinaan/index.vue"),
      },
      {
        path: "pembinaan/detail",
        name: "Detail",
        component: () => import("../views/home/pelaku/pembinaan/Detail.vue"),
      },
      {
        path: "sertifikasi",
        name: "Sertifikasi",
        component: () => import("../views/home/pelaku/Sertifikasi.vue"),
      },
    ],
  },
  {
    path: "/ticket/:id",
    name: "Ticket-Detail",
    component: () => import("../views/home/TicketDetail.vue"),
  },
  {
    path: "/katalog/:id",
    name: "Katalog-Detail",
    component: () => import("../views/home/catalogue/index.vue"),
  },
  {
    path: "/sertifikat/:id",
    name: "Certificate-Detail",
    component: () => import("../views/home/certificate/Detail.vue"),
  },
  {
    path: "/manfaat",
    name: "Manfaat",
    component: () => import("../views/home/Manfaat.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register.vue"),
  },
  {
    path: "/recovery",
    name: "Recovery",
    component: () => import("../views/auth/Recovery.vue"),
  },
  {
    path: "/user",
    component: () => import("../components/DashboardLayout.vue"),
    children: [
      {
        path: "home",
        name: "UserHome",
        component: () => import("../views/dashboard/index.vue"),
      },
      {
        path: "pembinaan",
        name: "UserPembinaan",
        component: () => import("../views/dashboard/Pembinaan.vue"),
      },
      {
        path: "lapor",
        name: "Lapor",
        component: () => import("../views/dashboard/Lapor.vue"),
      },
      {
        path: "pengajuan-isbn",
        name: "Pengajuan ISBN",
        component: () => import("../views/dashboard/isbn/index.vue"),
      },
      {
        path: "pengajuan-isbn/add",
        name: "ISBN-New",
        component: () => import("../views/dashboard/isbn/form/Add.vue"),
      },
      {
        path: "pengajuan-isbn/add-isbn",
        name: "ISBN-New",
        component: () => import("../views/dashboard/isbn/form/AddISBN.vue"),
      },
      {
        path: "pengajuan-isbn/edit/:id",
        name: "ISBN-Edit",
        component: () => import("../views/dashboard/isbn/form/Edit.vue"),
      },
      {
        path: "portfolio",
        name: "Portfolio",
        component: () => import("../views/dashboard/portfolio/index.vue"),
      },
      {
        path: "tugas",
        name: "Tugas",
        component: () => import("../views/dashboard/tugas/index.vue"),
      },
      {
        path: "portfolio/add",
        name: "Portfolio Add",
        component: () => import("../views/dashboard/portfolio/form/index.vue"),
      },
      {
        path: "tugas/add",
        name: "Tugas Add",
        component: () => import("../views/dashboard/tugas/form/index.vue"),
      },
      {
        path: "portfolio/edit/:id",
        name: "Portfolio-Edit",
        component: () => import("../views/dashboard/portfolio/form/index.vue"),
      },
      {
        path: "tugas/edit/:id",
        name: "Tugas-Edit",
        component: () => import("../views/dashboard/tugas/form/index.vue"),
      },
      {
        path: "profil",
        name: "Profil",
        component: () => import("../views/dashboard/profile/index.vue"),
      },
      {
        path: "profil/publisher",
        name: "Profil-Publisher",
        component: () => import("../views/dashboard/profile/Publisher.vue"),
      },
      {
        path: "/user/penilaian",
        name: "User-Penilaian",
        component: () => import("../views/dashboard/assesment/index.vue"),
      },
      {
        path: "/user/penilaian/btp",
        name: "User-Penilaian-BTP",
        component: () => import("../views/dashboard/assesment/form/BTP.vue"),
      },
      {
        path: "/user/penilaian/bnt",
        name: "User-Penilaian-BNT",
        component: () => import("../views/dashboard/assesment/form/BNT.vue"),
      },
      {
        path: "/user/penilaian/praregistrasi",
        name: "User-Penilaian-PraRegistrasi",
        component: () =>
          import("../views/dashboard/assesment/form/PreRegister.vue"),
      },
      {
        path: "/user/penilaian/praregistrasibtp",
        name: "User-Penilaian-PraRegistrasi-BTP",
        component: () =>
          import("../views/dashboard/assesment/form/PreRegisterBTP.vue"),
      },
      {
        path: "/user/agenda-penilaian",
        name: "User-Agenda-Penilaian",
        component: () => import("../views/dashboard/assesment/Event.vue"),
      },
      {
        path: "/user/penilaian/cetak",
        name: "User-Cetak-Izin",
        component: () => import("../views/dashboard/CetakBuktiBuku.vue"),
      },
      {
        path: "/user/penilaian/detail/:id",
        name: "User-Penilaian-Detail",
        component: () => import("../views/dashboard/assesment/Detail.vue"),
      },
      {
        path: "/user/penilaian/hasil/:id",
        name: "User-Penilaian-Hasil",
        component: () =>
          import("../views/dashboard/assesment/ResultAssesment.vue"),
      },
      {
        path: "/user/penilaian/revisi/:id",
        name: "User-Penilaian-Revisi",
        component: () =>
          import("../views/dashboard/assesment/HistoryRevision.vue"),
      },
      {
        path: "/user/penilaian/hasil-turnitin/:book_id",
        name: "ResultTurnitin",
        component: () => import("../views/dashboard/assesment/ResultTurnitin.vue"),
      },
      {
        path: "/user/izin-cetak",
        name: "Izin-Cetak",
        component: () =>
          import("../views/dashboard/print-permission/index.vue"),
      },
      {
        path: "/user/izin-cetak/k13",
        name: "Izin-Cetak-K13",
        component: () =>
          import("../views/dashboard/print-permission/Curriculum.vue"),
      },
      {
        path: "/user/izin-cetak/merdeka",
        name: "Izin-Cetak-Merdeka",
        component: () =>
          import("../views/dashboard/print-permission/Curriculum.vue"),
      },
      {
        path: "unduh",
        name: "Unduh",
        component: () => import("../views/dashboard/Unduh.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
